import React from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
const Ciudad = () => (
	              
    <div className="col-6 col-xs-12 col-sm-6 col-md-4 col-lg-3 mt-2 bg-white">
         <div className="title">
         <select>
            <option>Ingrese su Ciudad</option>
            <option>Medellín</option>
            <option>Pereira</option>
            <option>Neiva</option>
            <option>Manizales</option>
            <option>Soacha</option>
            <option>Valledupar</option>
            <option>Ibagué</option>
            <option>Santa Marta</option>
            <option>Bello</option>
            <option>Bogotá</option>
            <option>Armenia</option>
            <option>Villavicencio</option>
            <option>Pasto</option>
            <option>Soledad</option>
            <option>Bucaramanga</option>
            <option>Cartagena de Indias</option>
            <option>Montería</option>
            <option>Cúcuta</option>
            <option>Barranquilla</option>
            <option>Cali</option>
          </select >
         </div>             
    </div>

 
  
)

export default Ciudad;