import React from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
const Departamento = () => (
	              
    <div className="col-6 col-xs-12 col-sm-6 col-md-4 col-lg-3 mt-2 bg-white">
        <div className="title">
        <select>        
          <option>Ingrese su Departamento</option>
          <option>Atlántico</option>
          <option>Bolívar</option>
          <option>Nariño</option>
          <option>Santander</option>
          <option>Valle del Cauca</option>
          <option>Antioquia</option>
          <option>Atlántico</option>
          <option>Caldas</option>
          <option>Cesar</option>
          <option>Córdoba</option>
          <option>Cundinamarca</option>
          <option>Huila</option>
          <option>Magdalena</option>
          <option>Meta</option>
          <option>Norte de Santander</option>
          <option>Quindío</option>
          <option>Risaralda</option>
          <option>Tolima</option>
  
        </select>    
        </div>
                 
    </div>

 
  
)

export default Departamento;